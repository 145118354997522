<template>
  <input
    type="text"
    v-if="item.id === 'phone_number'"
    class="input"
    placeholder="38 (0__) ___ __ __"
    v-mask="'38 (0##)-###-##-##'"
    v-model="item.model"
  />
  <input
    type="email"
    v-else-if="item.id === 'email'"
    class="input"
    v-model="item.model"
  />
  <input
    type="password"
    v-else-if="item.id === 'password'"
    class="input"
    v-model="item.model"
  />
  <input
    v-else
    class="input"
    :placeholder="item.placeholder"
    v-model="item.model"
    @keydown="getNumber"
  />
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    getNumber(e) {
      let str = this.item.model;
      let specCharCode = [
        "!",
        "@",
        "#",
        "$",
        "%",
        "^",
        "&",
        "*",
        "(",
        ")",
        "-",
        "=",
        "\\",
        ",",
        "_",
        "+",
        "№",
        "/",
        "|",
        '"',
        "{",
        "}",
        "[",
        "]",
        ";",
        ":",
        "?",
        ">",
        "<",
        "'",
        "`",
        "~",
      ];

      if (this.item.type === "number") {
        let char = String.fromCharCode(e.keyCode);
        let calcVal = !/[A-Za-z]/.test(char);

        if (
          this.item.id !== "glucose" &&
          !specCharCode.includes(e.key) &&
          calcVal &&
          e.key !== "."
        ) {
          return true;
        } else if (
          this.item.id === "glucose" &&
          !specCharCode.includes(e.key) &&
          calcVal
        ) {
          if (e.key === ".") {
            if (!str.includes(".") && str.length) {
              return true;
            } else {
              return e.preventDefault();
            }
          } else {
            let idx = str.indexOf(".");
            if (idx !== -1 && str.length > idx + 2) {
              this.item.model = str.slice(0, idx + 2);
            } else {
              return true;
            }
          }
        } else e.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 13px;
  width: 100%;
  @media (max-width: 767px) {
    padding: 5px 10px;
  }
  &::placeholder {
    color: #6c757d;
  }
}
</style>